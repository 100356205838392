#root{
    width: 100%;
    max-height: 200%;
    font-family: 'Poppins', sans-serif;
    background-color: rgb(255, 255, 255);
}
.swal-button {
    padding: 7px 19px;
    border-radius: 2px;
    background-color: #0564DC;
    font-size: 12px;
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
  }
  .swal-button--cancel{
    padding: 7px 19px;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.3);
    font-size: 12px;
    color:white;
  }
  .swal-footer {
    text-align: center;
  }
  .swal-title{ 
      font-size: 20px;
      text-transform: none;
  }
  .swal-text{ 
      text-transform: none;
      font-size: 18px;
      color:rgb(65, 65, 65);
      text-align: center;
  }
  .swal-modal{ 
      width: 18rem;
  }