  
  .react-datepicker-wrapper {
    display: inline-block;
    width: 100%;
    border: 1px solid #084caa !important;
    padding: 0.4rem !important;
    color: #084caa !important;
    border-radius: 16px !important;
    background-color: white !important;
    overflow: hidden !important;
  }
  .react-datepicker__input-container input {
    outline: none !important;
    border-width:0 !important;
  }

  aside button{
    font-size: 15px !important;
  }