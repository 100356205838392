.hover-link:hover{
    background-color:#222222
}
.active-state{
    background-color: rgb(161, 161, 255)
}
.tab-card{
    min-height: 10rem;
    background-color: #fff;
    color: #0564DC;
}
.tab-card.active{
    min-height: 10rem;
    background-color: #0564DC;
    color:#fff;
}
.Downbutton{
    background-color:#0564DC;
     color:#fff;
     /* border-radius:10px; */
    padding:0.4rem; 
    border:1px solid white;
    justify-content: center;
}
.sidebar{
    background: linear-gradient(to right, #0066cc 0%, #33ccff 100%) !important;
}

.accountdata-main{
    overflow: auto !important;
}

aside .dropdown-toggle::after{
    position: absolute;
    right: 20px;
    font-size: 25px;
}
@media only screen and (max-width:896px){
    .accountdata-main{
        overflow: auto !important;
    }
    
    .nav-menu{
        width:60%;
        height: 100vh;
        display:flex ;
        flex-direction:column;
        align-items:center;
        position: fixed;
        top:0;
        left:-100%;
        transition: cubic-bezier(0.215, 0.610, 0.355, 1) 850ms;
        background-color:#363636;
        z-index:999;
    }
    .nav-menu.active{
        left:0;
        transition:350ms;
    }
    .right-section{
        position: fixed;
        top:0;
        right:0;
        width: 100%;
        height: 100vh;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
        transition:cubic-bezier(0.215, 0.610, 0.355, 1) 200ms;
    }
    .right-section.active-right{
        right:-55%;
        transition: cubic-bezier(0.215, 0.610, 0.355, 1) 410ms;
    }
    .dimensions-upload{
        width:95%;
        height:50%;
    }
    .dimension-show{
        width: 95%;
        height:50%
    }
    .card-img-top{
        width: 100%; 
        height: 65vw; 
        object-fit: cover;
    }
    .width-custom-dashboard-nav{
        width:60%;
    }
    #filelistscabinate{
        display: none;
    }
    #individualfilecabinate{
        display: none;
    }
    #barrier{
        display: none;
    }
    
}
@media only screen and (min-width:897px){
    .nav-menu{
        width: 18%;
        height:100vh;
        display:flex ;
        flex-direction:column;
        align-items:center;
        position: fixed;
        top:0;
        left:-100%;
        transition: cubic-bezier(0.215, 0.610, 0.355, 1) 850ms;
        background-color:#363636;
    }
    .nav-menu.active{
        left:0;
        transition: cubic-bezier(0.215, 0.610, 0.355, 1) 350ms;
    }
    .right-section{
        position: fixed;
        top:0;
        right:0;
        width: 100%;
        height: 100vh;
        transition:cubic-bezier(0.215, 0.610, 0.355, 1) 200ms;
    }
    .right-section.active-right{
        width: 82%;
        transition: cubic-bezier(0.215, 0.610, 0.355, 1) 410ms;
    }
    .dimensions-upload{
        width:75%;
        height:50%
    }
    .dimension-show{
        width: 75%;
        height:50%
    }
    .card-img-top{
        width: 100%; 
        height: 15vw; 
        object-fit: cover;
    }
    .empty-state{
        position: absolute;
        top:45%;
        left:45%;
    }
    .width-custom-dashboard-nav{
        width:30%;
    }
    #filelistsmobile{
        display: none;
    }
    .mobile{
      display: none;  
    }
    .hr-width{
        width: 50%;
    }

}